import React from 'react'
import './OrderingCards.scss'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function OrderingCards(props) {
  const { DELIVER_DATE, SELLING_PRICE, SELLING_UNIT, orderPrice, orderUnit, STATUS } = props.data
  const { OrderTakingSelection, index, OTSelection } = props
  const { t } = useTranslation()

  // CURRENCT FORMAT FUNCTION
  function formatToCurrency(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={`ORDERINGCARDS ${STATUS === "N" && "UPCOMMING__ORDER"} ${STATUS === "O" && OTSelection === index && "SELECTEDORDER"}`} onClick={() => OrderTakingSelection(index)}>
      <p className='ORDERINGCARDS__DATE'>
        {
          STATUS === "N" &&
          <div> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11360_9561)">
              <path d="M12.5 0.833252H7.5V2.49992H12.5V0.833252ZM9.16667 11.6666H10.8333V6.66658H9.16667V11.6666ZM15.8583 6.15825L17.0417 4.97492C16.6833 4.54992 16.2917 4.14992 15.8667 3.79992L14.6833 4.98325C13.3917 3.94992 11.7667 3.33325 10 3.33325C5.85833 3.33325 2.5 6.69159 2.5 10.8333C2.5 14.9749 5.85 18.3333 10 18.3333C14.15 18.3333 17.5 14.9749 17.5 10.8333C17.5 9.06659 16.8833 7.44159 15.8583 6.15825ZM10 16.6666C6.775 16.6666 4.16667 14.0583 4.16667 10.8333C4.16667 7.60825 6.775 4.99992 10 4.99992C13.225 4.99992 15.8333 7.60825 15.8333 10.8333C15.8333 14.0583 13.225 16.6666 10 16.6666Z" fill="#005541" />
            </g>
            <defs>
              <clipPath id="clip0_11360_9561">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          </div>
        }
        {STATUS != "N" && < div className='Date_Days'> {moment(DELIVER_DATE).format("ddd")}</div >}
        <div className='Date_Year'> {moment(DELIVER_DATE).format("D MMM YY")}</div >
      </p >
      {STATUS !== "N"
        ? <>
          {/* <span>{t("Unit Price")}</span> */}
          <p className='ORDERINGCARDS__SELLING__PRICE'>
            <span className="ORDERINGCARDS__CURRENCY">฿</span>
            <span className="ORDERINGCARDS__PRICE">{formatToCurrency(parseFloat(SELLING_PRICE).toFixed(2))}</span>
            <span className="ORDERINGCARDS__UNIT">/{SELLING_UNIT}</span>
          </p>
        </>
        : <p className='UPCOMMING__ORDER__CONTENT'
        // onClick={() => OrderTakingSelection('upcomming')}
        >

          <span>Upcoming</span>
        </p>
      }
    </div >
  )
}

export default OrderingCards