import React, { useEffect, useMemo, useRef, useState } from 'react'
import './PDPDetails.scss'
import request from '../../../../request'
import axios from 'axios'
import OrderingCards from './OrderingCards/OrderingCards'
import { useTranslation } from "react-i18next";
import carticon from "../../../../assets/images/catalog/shopping_cart.svg";
import { RequestQatation } from '../../../CatalogComponents/CatalogPopOver/RequestQatation'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useStateValue } from '../../../../store/state'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import MoreInfo from '../../MoreInfo/MoreInfo'
import Timer from './Timer/Timer'
import { ADDFAV, DELETEFAV, DateOrderDeliveryForStockProduct, SEASONS } from '../../../../store/PDP/Action'
import { DateOrderDelivery } from '../../../../store/PDP/Action'
import timer from '../../../../assets/images/PDP/Mobile/timer.svg'
import StockProductDateCards from './StockProductDateCards/StockProductDateCards'
import truck from "../../../../assets/images/PDP/delivery-truck.png";
import ProductCardSmall from '../../../ProductCardSmall/ProductCardSmall'

function PDPDetails({ pid, currentpdpdata, setrefreshpdp, setCurrentSku }) {
  const [mouseEnter, setMouseEnter] = useState(false)
  const { t } = useTranslation();
  const [qty, setQty] = useState(1);
  const [{ cart, SEASONS__DATA__LIST, ERP__HOLIDAYS__LIST, SIMILAR__PRODUCTS__DATA }, dispatch] = useStateValue();
  const [currentunits, setcurrentunits] = useState();
  const { page } = useParams();
  const qtyRef = useRef(1);
  const [OTSelection, setOTSELECTION] = useState(0)
  const [currentOrder, setCurrentOrder] = useState()
  const specialText = useRef()
  const [ORDERTAKING__DATES__DATA, setOrderingDates] = useState([]);
  const [addressdata, setaddressdata] = useState([]);
  const [defaultBranchId, setDefaultBranchId] = useState('');
  const addlist = useRef();
  const [branchAddresses, setBranchAddresses] = useState([]);
  const [dateavail, setdateavail] = useState([]);

  const getDayName = (day) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[day];
  }

  const isSunday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  const getNexAvaliableDate = (preferredDay, deliveryDates) => {
    function isDaballed(date) {
      if (!preferredDay) {
        return false;
      }

      const day = date.getDay();
      const dayName = getDayName(day);

      // Check if the day should be disabled based on the provided availability
      const isDayDisabled = preferredDay[dayName] === "Y";

      // Get the min and max dates within the selectable range
      const minSelectableDate = new Date(dateavail[0]?.DD);
      const maxSelectableDate = new Date(dateavail[dateavail?.length - 1]?.DD);

      // Check if the date is a Sunday and within the selectable range
      const isSundayAndSelectable =
        isSunday(date) &&
        date >= minSelectableDate &&
        date <= maxSelectableDate;

      // Check if the date is a holiday
      const isHoliday = ERP__HOLIDAYS__LIST.some((holiday) => {
        const holidayDate = new Date(holiday.HOLIDAY_DATE);
        return holidayDate.toDateString() === date.toDateString();
      });

      // Disable the date if it meets any of the specified conditions
      if ((!isDayDisabled && !isHoliday) || (isDayDisabled && isHoliday)) {
        return false;
      }

      // Disable the date if it's not within the selectable range, the day is marked as disabled, or it's a holiday
      // if (isSundayAndSelectable) {
      //   return isSundayAndSelectable
      // }
      if (isHoliday) {
        return isHoliday;
      }
      if (isDayDisabled) {
        return isDayDisabled;
      }
    }

    let availableDate = null;

    // Loop through each delivery date
    for (let i = 0; i < deliveryDates?.length; i++) {
      const currentDate = new Date(deliveryDates[i].DD);
      if (isDaballed(currentDate)) {
        availableDate = deliveryDates[i].DD;
        break;
      }
    }

    return availableDate;

  };

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("userid") === null) return;
      if (ORDERTAKING__DATES__DATA === null || ORDERTAKING__DATES__DATA?.length === 0) return;
      try {
        const address = await axios({
          method: "get",
          url: `${request.getbranch}/${localStorage.getItem("userid")}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setaddressdata(address.data);
        const objWithChkDefaultY = address?.data.find(obj => obj.chk_default === "Y");
        setDefaultBranchId(objWithChkDefaultY?.address_id);
        addlist.current = address.data;

        const addresses = address.data.map(data => ({
          city: data.city,
          country_name: data.country_name,
          customer_id: data.customer_id,
          firstname: data.firstname,
          id: data.address_id,
          branch_id: data.address_id,
          lastname: data.lastname,
          postalcode: data.post_code,
          region: data.state_name,
          region_code: data.state,
          region_id: data.state_id,
          street: data.street,
          telephone: data.telephone,
          branch: data.branch_name,
          items: [],
          Friday: data.d_fri,
          Monday: data.d_mon,
          Saturday: data.d_sat,
          Sunday: data.d_sun,
          Thursday: data.d_thu,
          Tuesday: data.d_tue,
          Wednesday: data.d_wed,
        }));
        setBranchAddresses(addresses);


        const preferredDay = addresses?.find((el) => el?.branch_id == objWithChkDefaultY?.address_id);

        const availableDate = getNexAvaliableDate(preferredDay, ORDERTAKING__DATES__DATA)
        // here need to find index 
        const availableDateIndex = ORDERTAKING__DATES__DATA?.findIndex(obj => obj.DD === availableDate);

        if (availableDateIndex === -1) {
          setOTSELECTION(0)
        } else {
          setOTSELECTION(availableDateIndex || 0)
        }

        if (ORDERTAKING__DATES__DATA && ORDERTAKING__DATES__DATA[availableDateIndex]) {
          setCurrentOrder(ORDERTAKING__DATES__DATA[availableDateIndex])
        }

      } catch (e) {
        console.log(e)
      }
    };

    fetchData();
  }, [localStorage.getItem("userid"), ORDERTAKING__DATES__DATA]);

  const AddtoFav = () => {
    if (view === true) {
      let message = `${currentpdpdata?.name} ${t("Removed from the Favourite Successfully")}`
      // deletefromFav();
      DELETEFAV(dispatch, pid, view, setView, toast, message, setrefreshpdp);
    } else {
      addToFavHandler();
      setrefreshpdp(true)
    }
  }

  const OrderTakingSelection = (data) => {

    if (ORDERTAKING__DATES__DATA[data]?.STATUS == "N") return
    // let selectableData = ORDERTAKING__DATES__DATA?.filter(el => el?.STATUS === "O")
    setOTSELECTION(data)
    setCurrentOrder(ORDERTAKING__DATES__DATA[data])
  }

  useEffect(() => {
    let selectableData = ORDERTAKING__DATES__DATA?.filter(el => el?.STATUS === "O")
    if (!selectableData?.length) {
      let upcomming = ORDERTAKING__DATES__DATA?.filter(el => el?.STATUS === "N")
      if (upcomming?.length) {
        setCurrentOrder(upcomming[0])
      }
    } else {
      setCurrentOrder(selectableData[0])
    }
  }, [ORDERTAKING__DATES__DATA])

  useEffect(() => {
    if (currentpdpdata?.sku != undefined) {
      SEASONS(dispatch, currentpdpdata?.sku);
    }
    // If stock products Need to get delivery dates from API-s
    if (currentpdpdata?.sku && currentpdpdata?.order_taking === 'N') {
      DateOrderDeliveryForStockProduct(currentpdpdata.sku)
        .then(res => {
          setOrderingDates(res);
        })
        .catch(error => {
          console.error('Error fetching order delivery dates:', error);
        });
    }
    // If order taking products Need to get delivery dates from API
    if (currentpdpdata?.sku && currentpdpdata?.order_taking != 'N') {
      DateOrderDelivery(currentpdpdata.sku)
        .then(res => {
          setOrderingDates(res);
        })
        .catch(error => {
          console.error('Error fetching order delivery dates:', error);
        });
    } else {
      // If SKU is not available, reset state
      setOrderingDates(null);
      // SEASONS(currentpdpdata?.sku)
    }
  }, [currentpdpdata?.sku])

  useEffect(() => {
    if (currentpdpdata?.options === undefined) {
      return;
    } else {
      setcurrentunits(currentpdpdata?.options[0]);
    }
    setCurrentSku(currentpdpdata?.sku)
  }, [currentpdpdata]);

  // useEffect(() => {
  //   //setcunit({ title: currentunits?.order_type, price: currentunits?.price });
  //  }, [currentunits]);

  // UPDATE QTY BY INPUT
  const updateqty = (value) => {
    if (value.toString()?.length > 4) return;
    setQty(value);
    qtyRef.current = value
  };

  // DECREMENT QTY
  const subtractQuantity = () => {
    if (qty === 1) {
      return;
    } else {
      setQty(qty - 1);
      qtyRef.current = qty - 1
    }
  };

  // INCREMENT QTY
  const addQuantity = () => {
    if (qty === 9999) return
    setQty(qty + 1);
    qtyRef.current = qty + 1
  };

  // API TO CHECK DATE WHEN ADDING TO THE CART
  const checkdate = async () => {
    try {
      const datechk = await axios({
        method: "get",
        url: `${request.erprequest}/products/${currentpdpdata?.sku}/delivery-date`,
        headers: {
          token: `Bearer ${localStorage.getItem("erpToken")}`,
        },
      });
      if (datechk.data.data[0].DELIVER_DATE[0].DD) {
        addToCart(datechk.data.data[0].DELIVER_DATE, currentOrder);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // API Call to add the prodcut to the cart
  const addToCart = async (date, OT) => {
    let defaultaddress;
    addressdata?.find((data) => {
      if (data?.chk_default == "Y") {
        defaultaddress = data;
      }
    });
    const findsku = cart.find((c) => c.sku === currentpdpdata?.sku);
    let slicedate
    if (currentpdpdata?.order_taking === "N") {
      slicedate = date?.slice(parseInt(defaultaddress?.leadtime_days))
    } else {
      slicedate = date
    }
    const cunit = getcunit(currentpdpdata)

    if (cunit?.price === undefined)
      return toast.info(`${t("UNITPRICEVALIDATIONS")}`);
    if (localStorage.getItem("token") === null)
      return toast.error(
        `${t("CARTADDVALIDATION")}`
      );

    if (
      currentpdpdata?.product_status
      === "2" &&
      currentpdpdata?.is_in_stock == 0 && OT?.STATUS !== "O"
    )
      return toast.info(`${currentpdpdata?.name} is ${t("COMINGSOON")}`);
    if (
      (currentpdpdata?.product_status === "2" &&
        currentpdpdata?.is_in_stock === "1" &&
        parseInt(currentpdpdata?.qty).toFixed(0) >= 1) ||
      currentpdpdata?.product_status === "3" || OT?.STATUS === "O"
    ) {
      try {
        const deliveryDate = currentOrder?.DELIVER_DATE || (currentOrder?.DD ? currentOrder.DD : slicedate[0]?.DD);
        const formattedDate = moment(deliveryDate).format("YYYY-MM-DD");

        const cartdata = await axios({
          method: "post",
          url: request.cartadd,
          data: {
            cartItem: {
              sku: currentpdpdata?.sku,
              quote_id: localStorage.getItem("cartid"),
              qty: qtyRef.current,
              price: OT?.STATUS === "O" ? OT?.ORDER_PRICE : cunit.price,
              extension_attributes: {
                unit: OT?.STATUS === "O" ? OT?.ORDER_UNIT : cunit?.title,
              },
            },
            splitorder: {
              company_id: localStorage.getItem("companyid"),
              customer_id: localStorage.getItem("userid"),
              branch_id: defaultaddress?.address_id ? defaultaddress?.address_id : null,
              product_id: pid,
              item_id: findsku?.item_id ? findsku?.item_id : 0,
              name: currentpdpdata?.name,
              sku: currentpdpdata?.sku,
              delivery_date: formattedDate,
              order_taking_request: specialText?.current?.value
            },
          },
        
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        {console.log(defaultaddress ,".address_id,")}
        dispatch({
          type: "CART_STATUS",
          status: cartdata.status,
          delivery_date: OT?.DELIVER_DATE || formattedDate
        });
        if (cartdata?.data[0]?.success) {
          toast.success(
            `${currentpdpdata?.name} ${t("CARTADD")}`
          );
        } else {
          toast.info(cartdata?.data[0]?.message);
          // toast.info(``)
        }
      } catch (e) {
        // toast.error(e.response?.data?.message);
      }
    } else {
      return toast.info(`${currentpdpdata?.name} ${t("COMINGSOON")}`);
    }
  };
  const getcunit = (currentdata) => {
    const data = currentdata?.options[0]
    return { title: data?.order_type, price: data?.price }
  }

  // CURRENCT FORMAT FUNCTION
  function formatToCurrency(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const productStatusMemo = useMemo(() => {
    let data = ORDERTAKING__DATES__DATA?.find(el => el?.STATUS === "O")
    return currentpdpdata?.is_in_stock == 0 &&
      currentpdpdata?.product_status === "1" ? (
      <button
        type="button"
        className="btn add_tocart pdp__cart__disabled"
        disabled
        aria-labelledby='pdp__addcart'
      >
        {t("COMINGSOON")}
      </button>
    ) : (
      <>
        {currentpdpdata?.order_taking === "N" && (
          <button
            type="button"
            className="btn add_tocart rippleeffect"
            onClick={() => checkdate()}
          >
            {window.innerWidth > 912 ? <img src={carticon} alt="" /> : ""}
            &nbsp;{t("ADDTOCART")}
          </button>
        )}
        {!data && currentpdpdata?.product_status === "3" &&
          currentpdpdata?.order_taking_end_date !== "" && (
            <button
              type="button"
              className="btn add_tocart ripple-effect"
              onClick={() => checkdate()}
            >
              {window.innerWidth > 912 ? <img src={carticon} alt="" /> : ""}
              &nbsp;{t("ADD")}
            </button>
          )}
        {!data && currentpdpdata?.product_status
          === "2" &&
          currentpdpdata?.order_taking_end_date === "" && (
            <button
              type="button"
              className="btn add_tocart pdp__cart__disabled"
              disabled
            >
              {window.innerWidth > 912 ? <img src={carticon} alt="" /> : ""}
              &nbsp;{t("NOTAVAILABLE")}
            </button>
          )}
        {currentpdpdata?.order_taking === "Y" && data?.STATUS === "O" && <button
          type="button"
          className="btn add_tocart ripple-effect"
          onClick={() => checkdate()}
        >
          {window.innerWidth >= 912 ? <img src={carticon} alt="" /> : ""}
          &nbsp;{t("ADDTOCART")}
        </button>}
      </>
    )
  }, [currentpdpdata, t, currentOrder])

  //   // Add to cart button
  const AddToCartMemo = useMemo(() => {
    return <>
      {window.innerWidth > 912 ? (
        <div className={`priceDetails__cartAdd ${currentpdpdata.order_taking == "Y" && "ordertaking__cartadd"}`}>
          <div className='priceInfo__quantity'>
            <button onClick={subtractQuantity} aria-labelledby='decrement' id="decrement" title="decrement" aria-label="decrement">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </button>
            <input type='number'
              onChange={(e) => updateqty(Math.trunc(e.target.value))}
              value={qty > 0 && qty}
              aria-label="qtyinput"
              aria-labelledby="qtyinput"
            />
            {/* <span className='priceInfo__currentQTY'>{qty}</span> */}

            <button onClick={() => addQuantity()} aria-labelledby='increment' id="increment" title="increment" aria-label="increment">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
          {/* <button className='priceInfo__addCart'>{t("Add")}</button> */}
          {productStatusMemo}

          {page !== "product" && currentpdpdata?.order_taking == "N" && currentpdpdata?.quote === false ?
            <RequestQatation
              className="pdpDetails__quotationButton"
              setMouseEnter={setMouseEnter}
              units={currentpdpdata?.selling_unit}
              price={currentpdpdata?.selling_unit_price}
              pid={pid}
              status={currentpdpdata?.quote_status}
              setrefreshpdp={setrefreshpdp}
              old_quote_price={currentpdpdata?.old_quote_price}
            /> : null}

        </div>)
        : (
          <div className='PDP__MOBILE__CARTADD'>
            {/* {currentpdpdata?.favourite ? <img src={MobieFav} onClick={AddtoFav} /> : <img src={MobileFavRem} alt="" onClick={AddtoFav} />} */}
            <div className='priceInfo__quantity'>
              <button onClick={subtractQuantity} aria-labelledby='decrement' id="decrement" title="decrement" aria-label="decrement">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                  <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                </svg>
              </button>
              <input type='number'
                onChange={(e) => updateqty(Math.trunc(e.target.value))}
                value={qty > 0 && qty}
                aria-label="qtyinput"
                aria-labelledby="qtyinput"
              />
              {/* <span className='priceInfo__currentQTY'>{qty}</span> */}
              <button onClick={addQuantity} aria-labelledby='increment' id="increment" title="increment" aria-label="increment">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </button>
            </div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="135" height="5" viewBox="0 0 135 5" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2.5C0.5 1.11929 1.61929 0 3 0H132C133.381 0 134.5 1.11929 134.5 2.5C134.5 3.88071 133.381 5 132 5H3C1.61929 5 0.5 3.88071 0.5 2.5Z" fill="#18273A" fill-opacity="0.94" />
  </svg> */}
            {productStatusMemo}
          </div>
        )}
    </>
  }, [currentpdpdata, qty, currentOrder, t])

  let foundFirstN = false;

  const priceInfoMemo = useMemo(() => {
    return <div className='pdpDetails__priceInfo'>

      {window.innerWidth > 912 ?
        (currentpdpdata?.order_taking == "Y" && <div className='Ordertaking_priceInfo__info'>
          {/*  Order taking product price start here*/}
          {currentpdpdata?.order_taking == "Y" ?
            currentOrder !== undefined ?
              <>
                <div className='pdp_price_addtocart_parent'>
                  <div className='pdp_price_block'>
                    <span className='info__currency'>฿
                      {currentOrder?.ORDER_PRICE
                        ? formatToCurrency(parseFloat(currentOrder?.ORDER_PRICE).toFixed(2))
                        : 'NAN'}
                      /
                    </span>
                    <span className='info__currency_unit'>
                      {currentOrder?.ORDER_UNIT ? t(currentOrder?.ORDER_UNIT) : 'NAN'}
                    </span>
                  </div>
                </div>
              </>
              :
              <>
                <span className='info__currency'>฿
                  {currentpdpdata?.options?.length > 0
                    ? formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2))
                    : 'NAN'}
                  /
                </span>
                <span className='info__currency_unit'>
                  {currentpdpdata?.options?.length > 0
                    ? t(currentpdpdata?.options[0]?.order_type)
                    : 'NAN'}
                </span>

              </> : null
          }
        </div >) : null
      }

      {/* Order taking and stock product mobile*/}
      {currentpdpdata?.order_taking === "Y" ?
        <div className='pdpDetails__orderCard'>
          {ORDERTAKING__DATES__DATA?.length &&
            <div className='pdpDetails__orderCard__header'>
              <Timer data={ORDERTAKING__DATES__DATA?.[OTSelection]} />


              {window.innerWidth > 912 ?
                <span>       {/* Order taking product Web*/}
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <g clip-path="url(#clip0_145_34112)">
                    <path d="M20 8.5H17V4.5H3C1.9 4.5 1 5.4 1 6.5V17.5H3C3 19.16 4.34 20.5 6 20.5C7.66 20.5 9 19.16 9 17.5H15C15 19.16 16.34 20.5 18 20.5C19.66 20.5 21 19.16 21 17.5H23V12.5L20 8.5ZM19.5 10L21.46 12.5H17V10H19.5ZM6 18.5C5.45 18.5 5 18.05 5 17.5C5 16.95 5.45 16.5 6 16.5C6.55 16.5 7 16.95 7 17.5C7 18.05 6.55 18.5 6 18.5ZM8.22 15.5C7.67 14.89 6.89 14.5 6 14.5C5.11 14.5 4.33 14.89 3.78 15.5H3V6.5H15V15.5H8.22ZM18 18.5C17.45 18.5 17 18.05 17 17.5C17 16.95 17.45 16.5 18 16.5C18.55 16.5 19 16.95 19 17.5C19 18.05 18.55 18.5 18 18.5Z" fill="#323232" />
                  </g>
                  <defs>
                    <clipPath id="clip0_145_34112">
                      <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg> */}
                  <span>{t("PDPTEXTONE")}</span>
                </span> :
                <span>  {/* Order taking product Mobile*/}
                  {/* <p>
                  <img src={timer} alt="" />
                  <span>{t("HURRYUP")}</span>
                </p> */}
                  <span>{t("PDPTEXTONE")}</span>
                </span>
              }
            </div>
          }

          {ORDERTAKING__DATES__DATA?.length && <div className='PDP__OC'>
            {ORDERTAKING__DATES__DATA?.map((data, i) => (
              <React.Fragment key={i}>
                {/* Display all content */}
                {data.STATUS === "O" && (
                  <OrderingCards data={data} OrderTakingSelection={OrderTakingSelection} index={i} OTSelection={OTSelection} />
                )}
              </React.Fragment>
            ))}
            {ORDERTAKING__DATES__DATA?.map((data, i) => (
              !foundFirstN && data.STATUS === "N" ? (
                <React.Fragment key={i}>
                  <OrderingCards
                    data={data}
                    OrderTakingSelection={OrderTakingSelection}
                    index={i}
                    OTSelection={OTSelection}
                  />
                  {foundFirstN = true} {/* Set foundFirstN to true after rendering the first match */}
                </React.Fragment>
              ) : null
            ))
            }
          </div>}

          <div className='get_fress_shipping'>
            <div>
              <img src={truck} alt="" />
            </div>
            <div className='get_fress_shipping_text'>
              <div className='get_fress_shipping_text_1'>{t("Get free shipping")}</div>
              <div className='get_fress_shipping_text_2'>{t("When you order at least ฿2,000")}</div>
            </div>
          </div>

          {window.innerWidth <= 912 ?
            <div className='PLP__MOBILE__PRICE'> {/* Order taking product Mobile*/}
              <p>
                {currentpdpdata?.order_taking == "Y" ?
                  currentOrder !== undefined ?
                    <>
                      <div className='pdp_addtocart_block_mobile'>
                        {AddToCartMemo}
                      </div>
                    </>
                    :
                    <>
                      <span className='info__currency'>฿</span>{(currentpdpdata?.options && formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                      <span> /{currentpdpdata?.options && t(currentpdpdata?.options[0]?.order_type)}</span>
                    </> : null
                }
                {currentpdpdata?.order_taking == "N" ?
                  <>
                    <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                    <span> /{currentpdpdata?.options[0]?.order_type}</span>
                    <span className='info__unitprice'>
                      ({`${t("UNITPRICE")}: ฿${currentpdpdata?.selling_unit_price && formatToCurrency(parseFloat(currentpdpdata?.selling_unit_price).toFixed(2))} / ${t(currentpdpdata?.selling_unit)}`})
                    </span>
                  </>
                  : null}
              </p>
            </div>
            : null}

          <div className='PLP__SPECIALREQUEST'>
            <label>{t("PDPTEXTTHREE")}</label>
            <input type='text' ref={specialText} placeholder='eg. approximate weight for this order' />
          </div>

          <div className='pdp_addtocart_block'>
            {AddToCartMemo}
          </div>
        </div>
        :
        window.innerWidth <= 912 ? <div className='MOBILE__priceInfo__info'>{/* for Stock Product in mobile here*/}
          {currentpdpdata && currentpdpdata?.length != 0 ?
            <>
              <>
                <div className='price_block_1'>
                  <p>
                    <span className='info__currency'>฿ {currentpdpdata?.options && (formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}</span>
                    <span> /{currentpdpdata?.options && currentpdpdata?.options[0]?.order_type}</span>
                  </p>
                  <p className='PLP__MOBILE__APPROXTEXT'> <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.33331 10H8.66665V11.3334H7.33331V10ZM7.33331 4.66671H8.66665V8.66671H7.33331V4.66671ZM7.99331 1.33337C4.31331 1.33337 1.33331 4.32004 1.33331 8.00004C1.33331 11.68 4.31331 14.6667 7.99331 14.6667C11.68 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.68 1.33337 7.99331 1.33337ZM7.99998 13.3334C5.05331 13.3334 2.66665 10.9467 2.66665 8.00004C2.66665 5.05337 5.05331 2.66671 7.99998 2.66671C10.9466 2.66671 13.3333 5.05337 13.3333 8.00004C13.3333 10.9467 10.9466 13.3334 7.99998 13.3334Z" fill="#192739" />
                  </svg> &nbsp;
                    {t("Approximate Price")}
                  </span>
                    {page === "product" ? <div className="pdpDetails__priceexpiry">
                      {t("Price Valid till")}:{" "}
                      {moment(currentpdpdata?.expired_at).format("YYYY-MM-DD")}
                    </div> : ""}
                  </p>
                </div>
                <div className='pdpDetails__orderCard'>
                  <p className='pdpDetails__orderCard__header'>
                    <span>
                      <span>{t("PDPTEXTONE")}</span>
                    </span>
                  </p>
                  <div className='PDP__OC_container'>
                    <div className='PDP__OC'>
                      {/* Here need to add stock product dates - mobile */}
                      {ORDERTAKING__DATES__DATA?.map((data, i) => (
                        i < 6 && (<React.Fragment key={i}>
                          {/* Display all content */}
                          <StockProductDateCards
                            key={`stockProductDateCard-${i}`} // Assigning a unique key
                            data={data} OrderTakingSelection={OrderTakingSelection} index={i} OTSelection={OTSelection} currentOrder={currentOrder} branchAddresses={branchAddresses} defaultBranchId={defaultBranchId} />
                        </React.Fragment>)
                      ))}

                    </div>
                  </div>
                  <div className='get_fress_shipping'>
                    <div>
                      <img src={truck} alt="" />
                    </div>
                    <div className='get_fress_shipping_text'>
                      <div className='get_fress_shipping_text_1'>{t("Get free shipping")}</div>
                      <div className='get_fress_shipping_text_2'>{t("When you order at least ฿2,000")}</div>
                    </div>
                  </div>
                </div>
              </>

              {/* stock product mobile */}
              <div className='pdp_addtocart_block_mobile'>
                {AddToCartMemo}
              </div>
            </> : null}
        </div> :
          null
      }


      {/* Both Order taking and stock product start Web here*/}
      {window.innerWidth > 912 ?
        <div className='priceInfo__info'>
          {/*  Order taking product start here*/}
          {/* {currentpdpdata?.order_taking == "Y" ?
          currentOrder !== undefined ?
            <>
              <div className='pdp_price_addtocart_parent'>
                <div className='pdp_price_block'>
                  <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentOrder?.ORDER_PRICE).toFixed(2)))}
                  <span> /{currentOrder?.ORDER_UNIT}</span>
                </div>
                <div className='pdp_addtocart_block'>
                  {AddToCartMemo}
                </div>
              </div>
            </>
            :
            <>
              <span className='info__currency'>฿
                {currentpdpdata?.options?.length > 0
                  ? formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2))
                  : 'NAN'}
                /
              </span>
              <span className='info__currency_unit'>
                {currentpdpdata?.options?.length > 0
                  ? t(currentpdpdata?.options[0]?.order_type)
                  : 'NAN'}
              </span>

            </> : null
        } */}

          {currentpdpdata?.order_taking == "N" ?
            <> {/*  Stock product start here*/}
              <div className='pdp_price_quote'>
                <div className='pdp_price_addtocart_parent'>
                  {/* <div className='pdp_price_block'>
                    <span className='info__currency'>฿{currentpdpdata?.options?.length ? (formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2))) : "-"}
                      / </span>
                    <span className='info__currency_unit'>{currentpdpdata?.options?.length ? currentpdpdata.options[0]?.order_type : '-'}</span>
                  </div> */}

                  <div className='pdp_price_block'>
                    {currentpdpdata?.selling_unit === currentpdpdata?.order_taking_unit ? (
                      <>
                        <span className='info__currency'>฿
                          {currentpdpdata?.options?.length ? formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)) : "-"}/
                        </span>
                        <span className='info__currency_unit'>
                          {currentpdpdata?.options?.length ? currentpdpdata.options[0]?.order_type : '-'}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className='info__currency'>฿
                          {currentpdpdata?.price ? formatToCurrency(parseFloat(currentpdpdata?.price).toFixed(2)) : '-'}/
                        </span>
                        <span className='info__currency_unit'>
                          {currentpdpdata?.order_taking_unit ? t(currentpdpdata?.order_taking_unit) : '-'}
                        </span>
                      </>
                    )}
                  </div>

                </div>
                {
                  window.innerWidth > 912 ? <div className='pdpDetails__quoteInfo'>
                    <span class={`pdpDetails__approxPrice ${currentpdpdata?.order_taking == "Y" && "approxPrice__orderTaking"}`}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.33331 9.99998H8.66665V11.3333H7.33331V9.99998ZM7.33331 4.66665H8.66665V8.66665H7.33331V4.66665ZM7.99331 1.33331C4.31331 1.33331 1.33331 4.31998 1.33331 7.99998C1.33331 11.68 4.31331 14.6666 7.99331 14.6666C11.68 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.68 1.33331 7.99331 1.33331ZM7.99998 13.3333C5.05331 13.3333 2.66665 10.9466 2.66665 7.99998C2.66665 5.05331 5.05331 2.66665 7.99998 2.66665C10.9466 2.66665 13.3333 5.05331 13.3333 7.99998C13.3333 10.9466 10.9466 13.3333 7.99998 13.3333Z" fill="#192739" />
                      </svg>
                      {t("Approximate Price")}</span>
                    {page === "product" ? <div className="pdpDetails__priceexpiry">
                      ({t("Price Valid till")}:{" "}
                      {moment(currentpdpdata?.expired_at).format("YYYY-MM-DD")})
                    </div> : ""}
                  </div> : null
                }
              </div>

              <div className='pdpDetails__orderCard'>
                <p className='pdpDetails__orderCard__header'>
                  {window.innerWidth > 912 ? <span>
                    <span>{t("PDPTEXTONE")}</span>
                  </span> :
                    <span>
                      <p>
                        <img src={timer} alt="" />
                        <span>{t("HURRYUP")}</span>
                      </p>
                      <span>{t("PDPTEXTTWO")}</span>
                    </span>
                  }
                </p>
                <div className='PDP__OC_container'>
                  <div className='PDP__OC'>
                    {ORDERTAKING__DATES__DATA?.map((data, i) => (
                      i < 6 && (<React.Fragment key={i}>
                        {/* Display all content */}
                        <StockProductDateCards
                          key={`stockProductDateCard-${i}`} // Assigning a unique key
                          data={data} OrderTakingSelection={OrderTakingSelection} index={i} OTSelection={OTSelection} currentOrder={currentOrder} branchAddresses={branchAddresses} defaultBranchId={defaultBranchId} />
                      </React.Fragment>)
                    ))}

                  </div>
                </div>
                {/* <div className='PLP__SPECIALREQUEST'>
                <label>{t("PDPTEXTTHREE")}</label>
                  <input type='text' ref={specialText} placeholder='eg. approximate weight for this order' />
              </div> */}
              </div>

              <div className='get_fress_shipping'>
                <div>
                  <img src={truck} alt="" />
                </div>
                <div className='get_fress_shipping_text'>
                  <div className='get_fress_shipping_text_1'>{t("Get free shipping")}</div>
                  <div className='get_fress_shipping_text_2'>{t("When you order at least ฿2,000")}</div>
                </div>
              </div>
              <div className='pdp_price_addtocart_parent'>
                <div className='pdp_addtocart_block'>
                  {AddToCartMemo}
                </div>
              </div>
            </>
            : null
          }

        </div > : null
      }


    </div >

  }, [currentpdpdata, t, ORDERTAKING__DATES__DATA, OTSelection, currentOrder, qty])
  function translatePackSize(packSize) {
    if (!packSize) return "";
  
    // Split by "X" to separate quantity and unit portions
    const [quantity, units] = packSize.split("X").map(part => part.trim());
  
    // Translation dictionary for units
    const translations = {
      "BG": "ถุง",
      "BK": "บล็อก",
      "BT": "ขวด",
      "BX": "กล่อง",
      "CA": "กระป๋อง",
      "CP": "ถ้วย",
      "CT": "กล่อง",
      "JR": "กระปุก",
      "KG": "กก.",
      "PA": "ถัง",
      "PC": "ชิ้น",
      "PK": "แพ็ค",
      "SC": "ซอง",
      "SH": "แผ่น",
      "TI": "กระปุก",
      "TR": "ถาด",
      "TU": "กระปุก",
      "TY": "ถาด",
      "GM": "ก.",
      "GL": "แกลลอน",
      "DR": "ถัง",
      "UN": "หน่วย",
      "DZ": "โหล",
      "LT": "ลิตร",
      "OZ": "ออนซ์",
      "ML": "มล.",
      "IT": "รายการ"
    };
  
    // Translate each part of quantity and unit
    const quantityTranslated = quantity
      .split(" ")
      .map(part => translations[part] || part)
      .join(" ");
    
    const unitsTranslated = units
      .split(" ")
      .map(part => translations[part] || part)
      .join(" ");
  
    return `${quantityTranslated} x ${unitsTranslated}`;
  }
  
  // Example usage in component
  const translatedPackSize = translatePackSize(currentpdpdata?.pack_size);
    const unitPriceMemo = useMemo(() => {
    return <div className='pdpDetails__unitprice'>
      <div className='unitPrice__content'>
        <div>
          <span className='pdpDetails__unitprice--label'>{t("PACKAGESIZE")}:</span>
          <span className='pdpDetails__unitprice--value' title={translatedPackSize}>
  {localStorage.getItem("storeid") === "1" ? currentpdpdata?.pack_size : translatedPackSize} 
</span>
        </div>
        <div class="vertical-line"></div>
        {/* Display the unit price only if order_taking is "N" (Stock Product) */}
        <div>
          <span className='pdpDetails__unitprice--label'>{t("UNITPRICE")}:</span>
          {currentpdpdata?.order_taking == "N" ? <span className='pdpDetails__unitprice--value' title={currentpdpdata?.storage}> ฿{currentpdpdata?.selling_unit_price && formatToCurrency(parseFloat(currentpdpdata?.selling_unit_price).toFixed(2))} / {t(currentpdpdata?.selling_unit)}</span> : "-"}
        </div>
      </div>
    </div>
  }, [currentpdpdata, t])

  const GreenCallender = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11816_40928)">
      <path d="M15 2.25H14.25V0.75H12.75V2.25H5.25V0.75H3.75V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 15.75H3V6H15V15.75Z" fill="#156B54" />
    </g>
    <defs>
      <clipPath id="clip0_11816_40928">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>

  const YellowCallender = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11816_40932)">
      <path d="M15 2.25H14.25V0.75H12.75V2.25H5.25V0.75H3.75V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 15.75H3V6H15V15.75Z" fill="#EB8D00" />
    </g>
    <defs>
      <clipPath id="clip0_11816_40932">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>;
  const RedCallender = <span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11816_40936)">
      <path d="M15 2.25H14.25V1.5C14.25 1.0875 13.9125 0.75 13.5 0.75C13.0875 0.75 12.75 1.0875 12.75 1.5V2.25H5.25V1.5C5.25 1.0875 4.9125 0.75 4.5 0.75C4.0875 0.75 3.75 1.0875 3.75 1.5V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM14.25 15.75H3.75C3.3375 15.75 3 15.4125 3 15V6H15V15C15 15.4125 14.6625 15.75 14.25 15.75Z" fill="#BA1A1A" />
    </g>
    <defs>
      <clipPath id="clip0_11816_40936">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
  </span>;

  const moreInfoMemo = useMemo(() => {
    return <MoreInfo data={currentpdpdata} seasonsData={
      <div className='PDP__SEASONS'>
        <div className='PDP__SEASONS_Content'>
          <div className='memo_heading'>
            <div><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g mask="url(#mask0_11816_40920)">
                <path d="M12 15C12.8333 15 13.5417 14.7083 14.125 14.125C14.7083 13.5417 15 12.8333 15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9C11.1667 9 10.4583 9.29167 9.875 9.875C9.29167 10.4583 9 11.1667 9 12C9 12.8333 9.29167 13.5417 9.875 14.125C10.4583 14.7083 11.1667 15 12 15ZM12 17C10.6167 17 9.4375 16.5125 8.4625 15.5375C7.4875 14.5625 7 13.3833 7 12C7 10.6167 7.4875 9.4375 8.4625 8.4625C9.4375 7.4875 10.6167 7 12 7C13.3833 7 14.5625 7.4875 15.5375 8.4625C16.5125 9.4375 17 10.6167 17 12C17 13.3833 16.5125 14.5625 15.5375 15.5375C14.5625 16.5125 13.3833 17 12 17ZM5 13H1V11H5V13ZM23 13H19V11H23V13ZM11 5V1H13V5H11ZM11 23V19H13V23H11ZM6.4 7.75L3.875 5.325L5.3 3.85L7.7 6.35L6.4 7.75ZM18.7 20.15L16.275 17.625L17.6 16.25L20.125 18.675L18.7 20.15ZM16.25 6.4L18.675 3.875L20.15 5.3L17.65 7.7L16.25 6.4ZM3.85 18.7L6.375 16.275L7.75 17.6L5.325 20.125L3.85 18.7Z" fill="#156B54" />
              </g>
            </svg>
            </div>
            <div>{t("PDPTEXTFOUR")} </div>
          </div>
          <span>{t("PDPTEXTFIVE")}</span>
          <div className='SEASONS__AVAILABLE'>
            <span className='SEASON__HIGHAVAILABLE'>{t("HIGHSEASON")}</span>
            <span className='SEASON__LOWAVAILABLE'>{t("LOWSEASON")}</span>
            <span className='SEASON__NOTAVAILABLE'>{t("NOTSEASON")}</span>
          </div>
        </div>
        {SEASONS__DATA__LIST?.length > 0 &&
          <div className='SEASON__DATA'>
            {SEASONS__DATA__LIST?.map(el =>
              <span key={el.id} className={
                `${el?.SEASON_FLAG == "G" && "SEASON__HIGH"} ${el?.SEASON_FLAG == "Y" && "SEASON__LOW"}`
              }>
                {el?.SEASON_FROM == "1" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Jan</div></div>
                }
                {el?.SEASON_FROM == "2" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Feb</div></div>
                }
                {el?.SEASON_FROM == "3" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Mar</div></div>
                }
                {el?.SEASON_FROM == "4" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Apr</div></div>
                }
                {el?.SEASON_FROM == "5" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>May</div></div>
                }
                {el?.SEASON_FROM == "6" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Jun</div></div>
                }
                {el?.SEASON_FROM == "7" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Jul</div></div>
                }
                {el?.SEASON_FROM == "8" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Aug</div></div>
                }
                {el?.SEASON_FROM == "9" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Sep</div></div>
                }
                {el?.SEASON_FROM == "10" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Oct</div></div>
                }
                {el?.SEASON_FROM == "11" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Nov</div></div>
                }
                {el?.SEASON_FROM == "12" &&
                  <div className='month_cal'>
                    <div>
                      {el?.SEASON_FLAG === "G" && <div> {GreenCallender}</div>}
                      {el?.SEASON_FLAG === "Y" && <div>{YellowCallender}</div>}
                    </div><div>Dec</div></div>
                }
              </span>)}
          </div>}

      </div>} />
  }, [currentpdpdata, t, SEASONS__DATA__LIST])

  const [view, setView] = useState(false);


  // FAVOURITES HANDLER
  const addToFavHandler = () => {
    const ctoken = localStorage.getItem("token");
    if (ctoken === null || ctoken === "" || ctoken === undefined) {
      setView(false);
      toast.warning(
        `${t("Please Login to add/Remove the product to Favourites.")}`
      );
    } else {
      let message = `${currentpdpdata?.name} ${t("Added to the Favourite Successfully")}`
      ADDFAV(dispatch, pid, setView, toast, message, toast)
    }
  };

  return (
    <div className='pdpDetails'>
      <div className='pdpDetails__name_sku'>
        <div className='pdpDetails__name' title={currentpdpdata?.name}>{currentpdpdata && (currentpdpdata?.name)}</div>
        <div className='pdpdetails__sku'>
          {t("SKU")} : {currentpdpdata?.sku}
        </div>
      </div>
      <div className='pdpDetails__size_unitprice'>
        {window.innerWidth > 912 ? unitPriceMemo : unitPriceMemo}
      </div>
      {priceInfoMemo}
      {window.innerWidth <= 912 && (
        SIMILAR__PRODUCTS__DATA?.length > 0 &&
        <div className='similar_product_mobile'>
          <div className='INFO__TITLE'>{t("SIMILARTEXT")}</div>
          <small className='INFO__TITLE1'>{t("CUSTOMERBOUGHT")}</small>
          <div className='PDPMOBILE__SIMILAR'>
            {SIMILAR__PRODUCTS__DATA?.map((data, i) => (
              <ProductCardSmall
                key={i}
                data={data}
                // setRefreshAPI={setRefreshAPI}
                // refreshAPI={refreshAPI}
                title={t("SIMILARTEXT")}
                page={"pdpmobile"}
              />
            ))}
          </div>
        </div>
      )}
      {moreInfoMemo}
      {
        window.innerWidth <= 912 && (<div className='mobile_request_quotation'>
          {page !== "product" && currentpdpdata?.order_taking == "N" && currentpdpdata?.quote === false ?
            <RequestQatation
              className="pdpDetails__quotationButton"
              setMouseEnter={setMouseEnter}
              units={currentpdpdata?.selling_unit}
              price={currentpdpdata?.selling_unit_price}
              pid={pid}
              status={currentpdpdata?.quote_status}
              setrefreshpdp={setrefreshpdp}
              old_quote_price={currentpdpdata?.old_quote_price}
            /> : null}
        </div>)
      }
    </div>
  )


  // return (
  //   <div className='pdpDetails'>
  //     {/* If the screen width is greater than 912px - Web */}
  //     {window.innerWidth > 912 ?
  //       <div className='pdpDetails__name_sku'>    {/* Display the product name with a tooltip */}
  //         <div className='pdpDetails__name' title={currentpdpdata?.name}>{currentpdpdata && (currentpdpdata?.name)}</div>
  //         <div className='pdpdetails__quotesku'>  {/* Conditionally render the SKU if the screen width is less than 912px - Mobile */}
  //           {window.innerWidth < 912 && <span>{currentpdpdata?.sku}</span>}  {/* Display a quotation message when mouseEnter is true */}
  //           {mouseEnter ?
  //             <span className='plpDetails__quotemsg'>{t("Look for bulk purchase? Please request quotation here.")}</span> : null}
  //           <div>{t("SKU")} : {currentpdpdata?.sku}</div>      {/* Display the SKU label and value - Web*/}

  //           {/* Commented out code for Request Quotation button - Subha */}
  //           {/* {page !== "product" && currentpdpdata?.order_taking == "N" ?
  //             <RequestQatation
  //               className="pdpDetails__quotationButton"
  //               setMouseEnter={setMouseEnter}
  //               units={currentpdpdata?.selling_unit}
  //               price={currentpdpdata?.selling_unit_price}
  //               pid={pid}
  //               status={currentpdpdata?.quote_status}
  //               setrefreshpdp={setrefreshpdp}
  //               old_quote_price={currentpdpdata?.old_quote_price}
  //             /> : null} */}
  //         </div>
  //       </div> : ""}
  //     {/* If the screen width is greater than 912px, render the unitPriceMemo - Web*/}
  //     {/* Otherwise, check if order_taking is "N" (Stock Product) and render size details */}
  //     {window.innerWidth > 912 ? unitPriceMemo : currentpdpdata?.order_taking === "N" &&
  //       <p>
  //         <span className='pdpDetails__unitprice--label'>{t("SIZE")}: {" "}</span>
  //         <span className='pdpDetails__unitprice--value' title={currentpdpdata?.pack_size}>{currentpdpdata?.pack_size}</span>
  //       </p>
  //     }
  //     {/* Render the price information */}
  //     {priceInfoMemo}
  //     {/* Render a horizontal line separator */}
  //     <span className='line'></span>
  //     {/* Render more information */}
  //     {moreInfoMemo}
  //   </div>
  // )
}

export default PDPDetails